.contact-form-content {
    width: 100%;
}

form {
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
}

form input,
form textarea {
    flex: 1;
    width: 100%;
    font-size: 0.9rem;
    color: #fff;
    border: 1.5px solid #1b77c7;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background: rgba(22, 17, 47, 0.398);
    -webkit-backdrop-filter: blur(1rem);
    backdrop-filter: blur(1rem);
    transition: all 0.3s ease-in-out;
}

form textarea {
    height: 160px;
}

form input[type=submit] {
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: linear-gradient(90deg, #7ec3ff 0%, #1b77c7 100%);
    padding: 0.6rem 2rem;
    border: none;
    outline: 1.5px solid transparent;
    border-radius: 0.6rem;
    cursor: pointer;
}

form input[type=submit]:hover {
    outline: 1.5px solid #7ec3ff;
}

.popup {
    background-color: #4caf50;
    color: white;
    text-align: center;
    vertical-align: middle;
    border-radius: 0.6rem;
    padding: 1rem 2.5rem;
    position: fixed;
    top: 8rem;
    right: 2rem;
    z-index: 999;
}

@media (max-width: 768px) {
    .popup {
        top: 5.5rem;
        right: 1.5rem;
    }
}