.project-item article {
  position: relative;
  overflow: hidden;
}

.project-item {
  width: 230px;
  margin-bottom: 1rem;
  position: relative;
  cursor: pointer;
}

.project-item img {
  width: 100%;
  border-radius: 0.6rem;
  transition: transform 0.3s ease;
}

.project-text {
  width: 100%;
  height: 100%;
  background-color: rgba(22, 17, 47, 0.5);
  text-align: center;
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  margin-inline: auto;
  padding: 0.8rem;
  border-radius: 0.6rem;
  color: #fff;
  transition: bottom 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1rem);
}

.project-item:hover .project-text {
  bottom: 0;
}

.project-item.active .project-text {
  bottom: 0;
}

.project-text a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  padding: 0 0.5rem;
}

.project-text a:hover {
  text-decoration: underline;
}

.project-text h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.project-text span.project-description {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.project-tech {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
}

.project-tech span {
  background-color: #0e427d;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 10px;
}

@media screen and (max-width: 768px) {
  .project-item {
    margin-bottom: 2rem;
  }
}