.hero-container {
    display:flex;
    align-items: center;
    position: relative;
}

.hero-container::after,
.hero-container::before {
    content: " ";
    width: 28.125rem;
    height: 28.125rem;
    border-radius: 28.125rem;
    background: #004b8d;
    position: absolute;
    z-index: -1;
    filter: blur(225px);
}

.hero-container::after {
    top: -3rem;
    left: -5rem;
}

.hero-container::before {
    bottom: 2rem;
    right: -5rem;
}

.hero-content {
    flex: 1;
}

.hero-content h2 {
    font-size: 3.8rem;
    font-weight: 600;
    line-height: 5rem;
    margin-bottom: 1rem;
}

.hero-content p {
    width: 80%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
}

.hero-content svg {
    margin-left: 15px;
}

.hero-btn {
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: linear-gradient(90deg, #7ec3ff 0%, #1b77c7 100%);
    padding: 0.6rem;
    border: none;
    outline: 1.5px solid transparent;
    border-radius: 0.6rem;
    cursor: pointer;
    text-decoration: none;
    margin-top: 2rem;
    max-width: 200px;
}

.hero-btn:hover {
    outline: 1.5px solid #7ec3ff;
}

.hero-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 5rem;
}

.hero-img > div {
    display: flex;
    align-items: flex-end;
    gap: 2rem;
}

.hero-img .home-img {
    background: url(../../assets/photo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0  0 0 9px rgb(255 255 255 / 10%);
    justify-self: center;
    width: 340px;
    height: 340px;
    animation: profile__animate 10s ease-in-out infinite 0s;
}

@keyframes profile__animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }

    50% {
        border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
    }

    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
    }
}

.hero-img > div > img {
    width: 25rem;
    transition: all 0.3s ease;
    border-radius: 2rem;
}

@media (max-width: 1460px) {
    .hero-container::after,
    .hero-container::before {
        content: "";
        width: 18rem;
        height: 18rem;
        border-radius: 18.125rem;
    }

    .hero-container::after {
        top: 0rem;
        left: 0rem;
    }

    .hero-container::before {
        bottom: 2rem;
        right: 0rem;
    }
}

@media (max-width: 1025px) {
    .hero content h2 {
        font-size: 3rem;
        line-height: 4rem;
    }

    .hero-content p {
        font-size: 0.9rem;
        line-height: 1.6rem;
    }

    .hero-img > div > img {
        width: 20rem;
    }
}

@media (max-width: 768px) {
    .hero-container {
        flex-direction: column;
    }

    .hero-content .hero-btn {
        margin: 2rem auto;
    }

    .hero-img {
        margin: 2rem 0;
    }

    .hero-container h2 {
        margin-top: 1rem;
    }

    .hero-content h2,
    .hero-content p {
        width: auto;
        text-align: center;
    }
}

@media (max-width: 600px) {
    .hero-content h2 {
        font-size: 2.2rem;
        line-height: 3rem;
    }

    .hero-content p {
        font-size: 0.8rem;
        line-height: 1.3rem;
    }

    .hero-img {
        gap: 1rem;
    }

    .hero-img > div {
        gap: 1rem;
    }

    .hero-img > div > img {
        width: 16rem;
    }
}

@media (max-width: 350px) {
    .hero-img .home-img {
        width: 180px;
        height: 180px;
    }

    .tech-icon {
        min-width: 20%;
    }
    .hero-img > div {
        flex-flow: row wrap;
        justify-content: center;
    }
}

