.container {
    max-width: 1300px !important;
    margin: 0 auto;
    position: relative;
}

h5 span {
    color:#5db3ff;
    font-size: 150%;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  
.spinner {
    border: 8px solid #ffffff;
    border-top: 8px solid #90c9fc;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}


@media (max-width: 1300px) {
    .container {
        padding: 0 1.5rem;
    }
}

@media (max-width: 769px) {
    .skills-container,
    .experience-container,
    .projects-container,
    .contact-container {
        margin: 1.5rem 0 !important;
    }

    .hero-container {
        margin: 0rem 0 !important;
    }
}