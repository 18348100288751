.skills-card {
    border-radius: 0.6rem;
    border: 1.5px solid #1b77c7;
    padding: 2.5rem;
    text-align: center;
    background: rgba(22, 17, 47, 0.398);
    backdrop-filter: blur(1rem);
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
}

.skills-card:hover,
.skills-card.active {
    background: linear-gradient(180deg, #75bcfa 0%, #1b77c7 100%);
    color: #000;
}

.skills-card:hover .skill-icon span,
.skills-card.active .skill-icon span {
    background: -webkit-linear-gradient(#c7e5ff, #92cafc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.skill-icon span {
    color: #1b77c7;
    font-size: 2.5rem;
    background: -webkit-linear-gradient(#75bcfa, #1b77c7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.skill-icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00223f;
    border-radius: 0.65rem;
    border: 1.4px solid #1b77c7;
    position: absolute;
    top: -1rem;
    left: -1rem;
}

@media (max-width: 1025px) {
    .skills-card {
        padding: 2rem;
    }

    .skills-card span {
        font-size: 1rem;
    }

    .skill-icon {
        width: 3rem;
        height: 3rem;
    }

    .skill-icon span {
        font-size: 1.8rem;
    }
}

@media (max-width: 768px) {
    .skills-card {
        padding: 1.6rem;
    }

    .skills-card span {
        font-size: 0.9rem;
    }

    .skill-icon span {
        font-size: 1.5rem;
    }
}