.nav-wrapper {
  background: rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  position: sticky;
  top: 0;
  z-index: 30;
  backdrop-filter: blur(50px);
}

.nav-content {
  max-width: 1300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  margin: 0 auto;
}

h1 span {
  color: #90c9fc;
}

h1 span.dot {
  color: #5db3ff;
  font-size: 150%;
}

h1.logo {
  font-size: 32px;
}

.nav-content ul {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  list-style: none;
}

.nav-content li {
  margin: 0 1rem;
  font-size: 1rem;
}

.menu-item {
  text-decoration: none;
  font-weight: 500;
  color: #fff;
  position: relative;
  cursor: pointer;
}

.menu-item::before {
  content: " ";
  width: 2rem;
  height: 0.2rem;
  background: linear-gradient(90deg, #7ec3ff 0%, #1b77c7 100%);
  border-radius: 0.5rem;
  position: absolute;
  bottom: -0.6rem;
  opacity: 0;
  transform: translateX(-1.5rem);
  transition: all 0.3s ease;
}

.menu-item:hover::before {
  width: 100%;
  transform: translateX(0);
  opacity: 1;
}

.contact-btn {
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: linear-gradient(90deg, #7ec3ff 0%, #1b77c7 100%);
  padding: 0.6rem 2rem;
  border: none;
  outline: 1.5px solid transparent;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: all 0.3 ease;
}

.contact-btn:hover {
  outline: 1.5px solid #7ec3ff;
}

.menu-btn {
  width: 2.5rem;
  height: 2.5rem;
  display: none;
  border: none;
  border-radius: 0.4rem;
  color: #fff;
  background: linear-gradient(90deg, #7ec3ff 0%, #1b77c7 100%);
  cursor: pointer;
  transition: all 0.4s ease;
  padding: 0.7rem 0.5rem;
}

.menu-btn .material-symbols-outlined {
  font-size: 1.5rem;
  line-height: 1rem;
  margin: 0 auto;
}

.menu-btn:hover {
  color: #7ec3ff;
  background: #000;
  border: 1px solid #7ec3ff;
}

.language-switcher .language-flag,
.language-switcher-mobile .language-flag {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
}

.language-switcher-mobile .language-flag {
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin-right: 5px;
}

.language-switcher {
  margin: 0 auto;
  min-width: 90px;
}

.language-switcher .language-flag:last-child {
  margin-right: 0;
}

.language-switcher-mobile {
  width: 100%;
}

.language-switcher-mobile .language-flag {
  float: right;
}

@media (max-width: 850px) {
  .menu-btn {
    display: block;
  }
  .nav-content ul {
    display: none;
  }
  h1.logo {
    font-size: 22px;
  }
  .nav-content {
    padding: 0.5rem 0;
  }
  .nav-content ul {
    gap: 0.1rem;
    padding-left: 0;
  }
  .contact-btn {
    padding: 0.5rem 1rem;
  }
  .nav-content li {
    margin: 0 0.4rem;
    font-size: 0.8rem;
  }
}

@media (max-width: 1325px) {
  .nav-wrapper {
    padding: 0 1rem;
  }
  .nav-content li {
    margin: 0 0.4rem;
    font-size: 0.9rem;
  }
  h1.logo {
    font-size: 26px;
  }
}
