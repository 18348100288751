.contact-details-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 1.5px solid #1b77c7;
  padding: 2rem;
  background: rgba(22, 17, 47, 0.398);
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  transition: all 0.3s ease-in-out;
  margin-bottom: 2rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

.contact-details-card:hover {
  border: 1.5px solid #79b8f0;
}

.contact-details-card .icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
  background: #0e427d;
  border-radius: 0.5rem;
}

.contact-details-card a {
  color: white;
  text-decoration: none;
}

.icon img {
  width: 1.8rem;
  height: auto;
  object-fit: contain;
}

.contact-details-card p {
  font-size: 0.9rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  .contact-details-card {
    flex-direction: row;
    padding: 1rem;
  }

  .contact-details-card .icon {
    margin-right: 0.8rem;
    padding: 0.8rem;
  }
}
