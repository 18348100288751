.projects-container {
  margin: 5rem 0;
  position: relative;
}

.projects-container::after,
.projects-container::before {
  content: " ";
  width: 28rem;
  height: 28rem;
  border-radius: 28.125rem;
  background: #004b8d;
  position: absolute;
  z-index: -1;
  filter: blur(200px);
}

.projects-container::after {
  top: -3rem;
  left: -5rem;
}

.projects-container::before {
  background: #0a63b1;
  bottom: 10rem;
  right: 2rem;
}

.projects-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
}

.projects-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 0 1rem;
  width: 100%;
}

.projects-filter-item {
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  border: 1.5px solid #1b77c7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 0.5rem;
}

.projects-filter-item:hover {
  background-color: #1b77c7;
}

.projects-filter-item.item-active {
  background: linear-gradient(90deg, #7ec3ff 0%, #1b77c7 100%);
  border: 1.5px solid #000;
  color: #000;
}

.projects-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch; /* Ustawienie elementów na tę samą wysokość */
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .projects-container h5 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  .projects-filter-item {
    margin: 0.3rem;
  }

  .projects-portfolio {
    gap: 1rem;
  }
}

@media (max-width: 1280px) {
  .projects-container::after,
  .projects-container::before {
    width: 6rem;
    height: 6rem;
    border-radius: 10.125rem;
  }

  .projects-container::after {
    top: 3rem;
    left: 0rem;
  }

  .projects-container::before {
    background: #0a63b1;
    bottom: 0rem;
    right: 2rem;
  }
}
