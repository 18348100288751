.experience-container {
  margin: 10rem 0;
  position: relative;
}

.experience-container::after,
.experience-container::before {
  content: " ";
  width: 28rem;
  height: 28rem;
  border-radius: 28.125rem;
  background: #1e5381;
  position: absolute;
  z-index: -1;
  filter: blur(200px);
}

.experience-container::after {
  top: 7rem;
  left: 3rem;
}

.experience-container::before {
  background: #0059be;
  bottom: 5rem;
  right: 1rem;
}

.experience-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.timeline {
  position: relative;
  min-width: 100%;
  padding-left: 30px;
  margin: 0 auto;
}

.timeline::before {
  content: "";
  position: absolute;
  width: 4px;
  background: #236fb1;
  top: 0;
  bottom: 0;
  left: 0;
  margin-left: -3px;
}

.timeline-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  position: relative;
}

.timeline-icon {
  background-color: #236fb1;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  position: absolute;
  left: -50px;
  top: 0;
}

.timeline-icon img {
  width: 20px;
  height: 20px;
}

.timeline-content {
  background-color: #222831;
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 0.65rem;
  border: 1.5px solid #1b77c7;
  background: rgba(22, 17, 47, 0.398);
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  width: 100%;
}

.timeline .date {
  color: #236fb1;
  margin: 0;
  font-size: 0.8rem;
}

.timeline .title {
  margin: 10px 0;
  font-size: 20px;
  font-weight: bold;
}

.timeline .location {
  font-size: 16px;
  margin-bottom: 10px;
}

.timeline .description {
  line-height: 1.7rem;
}

.timeline .description ul {
  padding-left: 5px;
}

.timeline .description ul li {
  padding-bottom: 5px;
}

.timeline-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
}

.timeline-tags,
.timeline-files {
  margin-top: 0.5rem;
}

.timeline-tags span {
  background-color: #0e427d;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
}

.file-link {
  text-decoration: underline;
  cursor: pointer;
}

.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-container {
  position: relative;
  cursor: auto;
}

.image-container img {
  max-width: 90%;
  max-height: 80%;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .experience-container h5 {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }
  .experience-container::after,
  .experience-container::before {
    width: 10rem;
    height: 10rem;
    border-radius: 18.125rem;
  }

  .timeline {
    padding-left: 25px;
  }

  .timeline-icon {
    left: -46px;
  }

  .timeline-content {
    padding: 1rem;
  }

  .timeline .description {
    line-height: 1.4rem;
    text-align: justify;
    text-justify: inter-word;
    font-size: small;
  }
}

@media (max-width: 1025px) {
  .experience-container::after,
  .experience-container::before {
    width: 10rem;
    height: 10rem;
    border-radius: 18.125rem;
  }
}
