.mobile-menu {
  width: 100vw;
  height: 100vh;
  display: none;
  background-color: rgba (0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999 !important;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateX(-100vw);
}

.language-switcher-mobile {
  display: none;
}

.mobile-menu-container {
  width: 60vw;
  height: 100vh;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
}

.mobile-menu.active {
  opacity: 1;
  transform: translateX(0);
}

.mobile-menu .logo {
  margin-bottom: 3rem;
}

.mobile-menu ul {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  list-style: none;
  margin-left: -2rem;
}

@media (max-width: 850px) {
  .mobile-menu,
  .language-switcher-mobile {
    display: block;
  }

}

@media (max-width: 350px) {
  .mobile-menu-container {
    width: 75vw;
  }
}
