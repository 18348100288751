.contact-container {
    margin: 10rem 0 2rem 0;
    position: relative;
}

.contact-container::after,
.contact-container::before {
    content: " ";
    width: 18rem;
    height: 20rem;
    border-radius: 28.125rem;
    background: #0059be;
    position: absolute;
    z-index: -1;
    filter: blur(200px);
}

.contact-container::after {
    top: -7rem;
    left: -2rem;
}

.contact-container::before {
    background: #1e5381;
    bottom: -2rem;
    right: 1rem;
}

.contact-container h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3.5rem;
}

.contact-content {
    display: flex;
    gap: 3rem;
}

@media (max-width: 768px) {
    .contact-content {
        flex-direction: column-reverse;
    }

    .contact-container h5 {
        font-size: 1.3rem;
        margin-bottom: 2rem;
    }

    .contact-container::after,
    .contact-container::before {
    width: 10rem;
    height: 10rem;
    border-radius: 18.125rem;
    }
}